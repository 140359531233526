/*
 *
 * LanguageProvider reducer
 *
 */

import { CHANGE_LOCALE } from './constants';
import { DEFAULT_LOCALE } from '../../i18n';
import stateModifier from '../../utils/stateModifier';

export const initialState = {
  locale: DEFAULT_LOCALE,
};

const actionMap = new Map();
actionMap.set(CHANGE_LOCALE, (state, action) => {
  return {
    ...state,
    locale: action.locale,
  };
});

/* eslint-disable default-case, no-param-reassign */
// const languageProviderReducer = (state = initialState, action) =>
//   produce(state, draft => {
//     switch (action.type) {
//       case CHANGE_LOCALE:
//         draft.locale = action.locale;
//         break;
//     }
//   });

// export default languageProviderReducer;

export default (state = initialState, action) => {
  return stateModifier(state, action, actionMap);
};