import { put } from "redux-saga/effects";
import { get as _get } from "lodash";

export const SERVER_URL = _get(
  process.env,
  "REACT_APP_API_URL",
  "https://api-dit.connectcdk.com/api/cs-common-login-api-perf/v1"
);


export const get = (url, isExternal = false, needHeader = true, includeCredentials = true, headers = {}) => {
  let reqUrl = isExternal ? url : SERVER_URL + url;
  let body = needHeader
    ? { method: "GET", headers: getHeaders(headers), credentials: includeCredentials? "include" : "omit"}
    : { method: "GET", credentials: includeCredentials? "include" : "same-origin" };
  // console.log("reqUrl :", reqUrl);

  return fetch(reqUrl, body)
    .then((res) => {
      // console.log("reqUrlFE :", reqUrl);
      return res;
    }, onFailed)
    .catch((err) => {
      console.log("errr :", err);
      throw err;
    });
};

export const post = (url, data, isExternal = false, needHeader = true, includeCredentials = true, headers = {}) => {
  let reqUrl = isExternal ? url : SERVER_URL + url;
  let body = needHeader
    ? {
        method: "POST",
        headers: getHeaders(headers),
        body: JSON.stringify(data),
        credentials: includeCredentials? "include" : "omit",
      }
    : { method: "POST", body: JSON.stringify(data), credentials: includeCredentials? "include" : "same-origin" };

  return fetch(reqUrl, body)
    .then((res) => res, onFailed)
    .catch((err) => {
      console.log("errr :", err);
      console.error("errr :", err);
      throw err;
    });
};

export function del(url, isExternal = false, includeCredentials = true) {
  let reqUrl = isExternal ? url : SERVER_URL + url;
  return fetch(reqUrl, {
    method: "DELETE",
    headers: getHeaders(),
    credentials: includeCredentials? "include" : "same-origin",
  })
    .then((res) => res, onFailed)
    .catch((err) => {
      console.log("errr :", err);
      console.error("errr :", err);
      throw err;
    });
}
const extractHeaders = (response) => {
  let responseHeaders = [];
      for(const header of response.headers){
        // console.log(`Name: ${header[0]}, Value:${header[1]}`);
        const name = header[0];
        const val = header[1];
        let h = {};
        h[name] = val;
        responseHeaders.push(h);
      }
  // console.log("headersResult :", responseHeaders);
  return responseHeaders;
}
const extractResponse = (responseBody) => {
  let resp = responseBody;
  try {
    resp = JSON.parse(responseBody);
  } catch (e) {
    return resp;
  }
  return resp;
};
export function* common(action, fn, requireStatus = false) {
  try {
    yield put({ type: onStart(action.type), data: action.data });
    const response = yield fn(action);
    let responseBody = yield response.text();
    let resp = extractResponse(responseBody);
    if (response.ok) {
      yield put({
        type: onFinish(action.type),
        data: resp,
        headers: extractHeaders(response)
      });
    } else {
      yield put({
        type: onError(action.type),
        data: { ...resp, code: response.status },
      });
    }
    return requireStatus ? {...resp, statusCode: response.status } : resp;
  } catch (e) {
    yield put({ type: onError(action.type), data: e.message });
  }
}

const onFailed = (err) => {
  console.log("Error :", err);
  throw err;
};

export const redirect = (redirectUrl, token) => {
  console.log("redirecting final call: " + redirectUrl + "?code=" + token.code);
  window.location =
    redirectUrl +
    `?code=${token.code}&state=${token.state}&id_token=${token.id_token}`;
};

const getHeaders = (headers = {}) => {
  return {
    ...headers,
    "content-type": "application/json",
  };
};

export const anyOnStart = (type) => type.substring(type.indexOf("_INITIATED"));
export const anyOnFinish = (type) => type.substring(type.indexOf("_FINISHED"));

export const INITIATED = "_INITIATED";
export const FINISHED = "_FINISHED";

export const onStart = (type) => type + "_INITIATED";
export const onFinish = (type) => type + "_FINISHED";
export const onError = (type) => type + "_ERROR";
