// import logoCDK from "../../images/CDK_Logo_Horiz_Reg_RGB.svg";
import logoCDK from "../../images/cdkglobal_wm_blk.svg";
import logoCVR from "../../images/CVR-logo.png";
import logoFord from "../../images/FordDirect_Logo_Approved.svg";
import { isEmpty } from "lodash";

export const CVR_BRAND_NAME = "CVR";
export const FORD_BRAND_NAME = "FordDirect";

let productMap = [
  { product: "cvrconnect", img: logoCVR, name: CVR_BRAND_NAME },
  { product: "forddirect", img: logoFord, name: FORD_BRAND_NAME },
  {
    product: "default",
    img: logoCDK,
    name: "CDK Global",
    isConnectCdk: true,
  },
];

const hasProductName = (productName, originalTarget) => {
  const hostName = window.location.hostname;
  return (
    (originalTarget && originalTarget.includes(productName)) ||
    (hostName && hostName.includes(productName))
  );
};

export const getDefaultBrand = () =>
  productMap.find((n) => n.product === "default");

export const getBrand = (originalTarget) => {
  let productData = Object.values(productMap).find((v) =>
    hasProductName(v.product, originalTarget)
  );
  let result =  !isEmpty(productData) ? productData : getDefaultBrand();
  return result;
};

// getBrand("https://forddirect-qa.connectcdk.com/");

export const setFavIconElement = (productBrand) => {
  const favicon = document.getElementById("favicon");
  if (productBrand && !productBrand.isConnectCdk)
    favicon.href = productBrand.img;
};
