export const getUrlToResolveOidcParams = (hostName) => {
  let redirectUrl = "https://connectcdk.com/";
  if (hostName.includes("cvrconnect")) {
    redirectUrl =
      hostName.includes("-dit") || hostName.includes("-qa")
        ? "https://access-qa.cvrconnect.com/"
        : "https://access.cvrconnect.com/";
  } else if (hostName.includes("-perf.connectcdk")) {
    redirectUrl = "https://www-perf.connectcdk.com/";
  } else if (hostName.includes("-dit.connectcdk")) {
    redirectUrl = "https://www-dit.connectcdk.com/";
  } else if (hostName.includes("-qa.connectcdk")) {
    redirectUrl = "https://www-qa.connectcdk.com/";
  } else if (hostName.includes("-dev.connectcdk")) {
    redirectUrl = "https://www-dev.connectcdk.com/";
  } else if (hostName.includes("-devqa.connectcdk")) {
    redirectUrl = "https://admin-devqa.connectcdk.com/";
  }
  console.log("redirectUrl :" + redirectUrl);
  return redirectUrl;
};

// hostName.includes("-dev.connectcdk")

export const getOidcAsUrlParams = (oidcParams) => {
  let oidc =
    `client_id=${encodeURIComponent(oidcParams.client_id)}` +
    `&response_type=${encodeURIComponent(oidcParams.response_type)}` +
    `&scope=${encodeURIComponent(oidcParams.scope)}` +
    `&redirect_uri=${encodeURIComponent(oidcParams.redirect_uri)}` +
    `&nonce=${encodeURIComponent(oidcParams.nonce)}` +
    `&state=${encodeURIComponent(oidcParams.state)}`;

  if (oidcParams.response_mode) {
    oidc += `&response_mode=${oidcParams.response_mode}`;
  }

  if (oidcParams.code_challenge) {
    oidc += `&code_challenge=${oidcParams.code_challenge}`;
  }
  if (oidcParams.code_challenge_method) {
    oidc += `&code_challenge_method=${oidcParams.code_challenge_method}`;
  }
  if (oidcParams.idp) {
    oidc += `&idp=${oidcParams.idp}`;
  }

  return oidc;
};

export const getOidcFromParams = (params) => ({
  client_id: params.get("client_id"),
  response_type: params.get("response_type"),
  scope: params.get("scope"),
  redirect_uri: params.get("redirect_uri"),
  code_challenge: params.get("code_challenge"),
  code_challenge_method: params.get("code_challenge_method"),
  response_mode: params.get("response_mode"),
  idp: params.get("idp"),
  nonce: params.get("nonce"),
  state: params.get("state"),
});
