import { Route, Switch } from "react-router";
import React, { Component, Suspense } from "react";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./animation.css";
import TestContainer from "./pages/TestContainer";
import { SERVER_URL } from "./httpService";
import { getOidcAsUrlParams, getOidcFromParams } from './utils/oidcService';

const PreLoginContainer = React.lazy(() => import('./pages/Login/PreLoginContainer'));
const LoginContainer = React.lazy(() => import('./pages/Login'));
const SetupSecurityQuestion = React.lazy(() => import('./pages/SetupSecurityQuestion'));
const CreateNewPassword = React.lazy(() => import('./pages/CreateNewPassword'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));

const UpdateContactInfo = React.lazy(() => import('./pages/UpdateContact'));
const VerifyPasscode = React.lazy(() => import('./pages/VerifyPasscode'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const VerifySecurityQuestion = React.lazy(() => import('./pages/VerifySecurityQuestion'));
const EmailConfirmation = React.lazy(() => import('./pages/EmailConfirmation'));
const TokenVerification = React.lazy(() => import('./pages/TokenVerification'));
const FederatedDriveSSO = React.lazy(() => import('./pages/FederatedDriveSSO'));
const Logout = React.lazy(() => import('./pages/Logout'));
const LogoutIdp = React.lazy(() => import('./pages/Logout/loggedOut'));
const MfaFactor = React.lazy(() => import('./pages/MfaFactor'));
const VerifyMfaFactor = React.lazy(() => import('./pages/VerifyMfaFactor'));
const VerifyMfaFactorFirstTime = React.lazy(() => import('./pages/VerifyMfaFactorFirstTime'));
const EnrollMfa = React.lazy(() => import('./pages/EnrollMFA'));
const EnrollSpecificMfa = React.lazy(() => import('./pages/EnrollSpecificMFA'));
const ErrorPage = React.lazy(() => import('./pages/FederatedDriveSSO/ErrorPage'));
const SessionExpired = React.lazy(() => import('./pages/Logout/sessionExpired'));


export default class AppRouter extends Component {


  authorize = (props) => {
    let params = new URLSearchParams(props.location.search);
    let oidc = getOidcFromParams(params);
    window.location = SERVER_URL + "/authorize?" + getOidcAsUrlParams(oidc);
  };

  render() {
    return (
      <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={250} classNames="fade">
                <Switch location={location}>
                  <Route path="/" exact component={PreLoginContainer} />
                  <Route path="/login" exact component={LoginContainer} />
                  <Route path="/test" exact component={TestContainer} />
                  <Route
                    path="/new-password"
                    exact
                    component={CreateNewPassword}
                  />
                  <Route
                    path="/change-password"
                    exact
                    component={ChangePassword}
                  />
                  <Route path="/authorize" exact component={this.authorize} />

                  <Route
                    path="/security-question"
                    exact
                    component={SetupSecurityQuestion}
                  />
                  <Route
                    path="/multifactor"
                    exact
                    component={UpdateContactInfo}
                  />
                  <Route
                    path="/verify-multifactor"
                    exact
                    component={VerifyPasscode}
                  />
                  <Route
                    path="/forgot-password"
                    exact
                    component={ForgotPassword}
                  />
                  <Route
                    path="/verify-security-question"
                    exact
                    component={VerifySecurityQuestion}
                  />
                  <Route
                    path="/email-confirmation"
                    exact
                    component={EmailConfirmation}
                  />
                  <Route
                    path="/mfa-authentication"
                    exact
                    component={MfaFactor}
                  />
                  <Route
                    path="/mfa-auth-verification"
                    exact
                    component={VerifyMfaFactor}
                  />
                  <Route
                    path="/new-mfa-auth-verification"
                    exact
                    component={VerifyMfaFactorFirstTime}
                  />
                   <Route
                    path="/mfa-enroll"
                    exact
                    component={EnrollMfa}
                  />
                    <Route
                    path="/mfa-enroll-factor"
                    exact
                    component={EnrollSpecificMfa}
                  />
                  <Route
                    path="/password-recovery"
                    exact
                    component={TokenVerification}
                  />
                  <Route path="/federation" exact component={FederatedDriveSSO} />
                  <Route path="/logout" exact component={Logout} />
                  <Route path="/logout-idp" exact component={LogoutIdp} />
                  <Route path="/session-expired" exact component={SessionExpired} />
                  <Route path="/error-page" exact component={ErrorPage} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
        </Suspense>
      </div>
    );
  }
}
