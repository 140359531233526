// Needed for redux-saga es6 generator support
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'core-js/features/string/repeat';
import Promise from "promise-polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import '@formatjs/intl-relativetimeformat/polyfill';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router';
import Store from "./store.js";
import { ThemeProvider } from 'styled-components';
import { theme } from 'cdk-radial';
import { translationMessages } from './i18n';
import LanguageProvider from './containers/LanguageProvider';
import reportWebVitals from './reportWebVitals';
import history from './utils/history';

const customTheme = {
  ...theme,
  // Overriding existing theme variables
  color: {
    ...theme.color,
    primary: {
    "50": {
        "value": "#000000"
    },
    "100": {
        "value": "#000000"
    },
    "200": {
        "value": "#333333"
    },
    "300": {
        "value": "#333333"
    },
    "400": {
        "value": "#333333"
    },
    "500": {
        "value": "#000000"
    },
    "600": {
        "value": "#FFFFFF"
    },
    "700": {
        "value": "#333333"
    },
    "800": {
        "value": "#000000"
    },
    "900": {
        "value": "#4D4D4D"
    }
    },
    state: {
      ...theme.color.state,
      primary: {
        "disabled": {
            "value": "rgba(0, 0, 0, 0.1)"
        },
        "hover": {
            "value": "rgba(0, 0, 0, 0.1)"
        },
        "focus": {
            "value": "{color.state.primary.hover.value}"
        },
        "pressed": {
            "value": "#4D4D4D"
        },
        "selected": {
            "value": "#4D4D4D"
        }
     }
    }
  },
};

const MOUNT_NODE = document.getElementById('root');

const initialState = {};
const store = Store(initialState, history);

const render = (messages) => {
  ReactDOM.render(
    <ThemeProvider theme={customTheme}>
      <Provider store={store}>
        <LanguageProvider locale={"en"} messages={messages}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </LanguageProvider>
      </Provider>
    </ThemeProvider>,
    MOUNT_NODE,
  );
};

render(translationMessages);

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', './App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('@formatjs/intl'));
  })
    .then(() => Promise.all([import('@formatjs/intl-relativetimeformat/locale-data/en')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
