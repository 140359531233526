import stateModifier from "./utils/stateModifier";

export const initialState = {
  displayBackgroundImage: true
};


const actionMap = new Map();

actionMap.set("SHOW_BACKGROUND", (state, action) => ({
  ...state,
  displayBackgroundImage: action.data
}));

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  return stateModifier(state, action, actionMap);
};
