
import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router';
import languageProviderReducer from './containers/LanguageProvider/reducer'
import history from './utils/history';

const rootReducer = (injectedReducers = {}) => {
    return combineReducers({
        language: languageProviderReducer,
        router: connectRouter(history),
        ...injectedReducers,
      });
}

export default rootReducer;
