import {isFunction} from "lodash";

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action, actionMap) => {
  let actionCallback = actionMap.get(action.type);
  if (actionCallback && isFunction(actionCallback)) {
    return actionCallback(state, action);
  } else {
    return state;
  }
};
