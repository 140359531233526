// Needed for redux-saga es6 generator support
import React, {useEffect} from "react";
import AppRouter from "./AppRouter";
import styled from "styled-components";
import CdkBackground from "./images/SSO_Background_Image.jpg";
import reducer from "./AppReducer";
import { useInjectReducer } from "./utils/injectReducer";
import { useSelector, useDispatch } from "react-redux";
import { get, isEmpty } from "lodash";
import {
  CVR_BRAND_NAME,
  FORD_BRAND_NAME,
  getBrand,
  setFavIconElement,
  getDefaultBrand
} from "./pages/CommonLayout/ProductBrand";
import { useCookies } from "react-cookie";
import getFromCookie from './utils/cookieUtil';

function App() {
  useInjectReducer({ key: "app", reducer });
  const displayBackgroundImage = useSelector((state) =>
    get(state, "app.displayBackgroundImage", true)
  );
  let dispatch = useDispatch();
  let cookies = useCookies();

  useEffect(() => {
    let originalTarget = getFromCookie(cookies, "originalTarget");
    console.log("originalTarget :", originalTarget);
    let productBrand =
      !isEmpty(originalTarget) && getBrand(originalTarget);

    productBrand = productBrand? productBrand : getDefaultBrand();
    setFavIconElement(productBrand);
    if (
      !isEmpty(productBrand) &&
      (productBrand.name === CVR_BRAND_NAME ||
        productBrand.name === FORD_BRAND_NAME)
    ) {
      if (displayBackgroundImage) {
        dispatch({
          type: "SHOW_BACKGROUND",
          data: false,
        });
      }
    } else {
      if (!displayBackgroundImage) {
        dispatch({
          type: "SHOW_BACKGROUND",
          data: true,
        });
      }
    }
  }, [cookies, dispatch, displayBackgroundImage]);

  return (
    <MainScreen>
      {displayBackgroundImage && <AppBackground id="CommonLayoutBackground" />}
      <AppRouter />
    </MainScreen>
  );
}

const MainScreen = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const AppBackground = styled.div`
  width: 100%;
  height: 100%;
  display: inline;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-size: cover;
  background-image: url(${CdkBackground});
`;

export default App;
