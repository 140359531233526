/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

const en = import ("@formatjs/intl-relativetimeformat/locale-data/en");
const fr = import("@formatjs/intl-relativetimeformat/locale-data/fr");

const enTranslationMessages = require("./translations/en.json");
const frTranslationMessages = require("./translations/fr.json");

export const DEFAULT_LOCALE = "en";

// prettier-ignore
export const appLocales = [
  'en',
  'fr',
];

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  fr: formatTranslationMessages("fr", frTranslationMessages),
};

export const getLoacleMessages = (intl, fields, messages) => {
  // eslint-disable-next-line prefer-const
  let result = {};
  const addToResult = (field) => {
    result[field] = intl.formatMessage(messages[field]);
    console.log(JSON.stringify(messages[field]));
  };
  
  fields.forEach(addToResult);
  return result;
};
