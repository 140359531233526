import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import combinedReducer from "./reducers";
import history from "./utils/history";
import { get } from 'lodash';

const DEBUG = get(process.env, "REACT_APP_DEBUG", "false");

const initStore = (initialState = {}) => {
  const rootReducer = combinedReducer();
  const saga = createSagaMiddleware();

  // Create the store with two middlewares
  // 1. saga: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state

  const middlewares = [saga, routerMiddleware(history)];
  let store;

  if (DEBUG && DEBUG === "true") {
    const composeDevTools = composeWithDevTools(
      applyMiddleware(...middlewares)
    );
    store = createStore(rootReducer, initialState, composeDevTools);
  } else {
    store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));
  }

  store.runSaga = saga.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {};

  // Make reducers hot reloadable
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      store.replaceReducer(combinedReducer(store.injectedReducers));
    });
  }

  return store;
};

export default initStore;
