import React from "react";

const TestContainer = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      margin: "10px",
      padding: "10px",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "yellow",
      }}
    >
      <a href="http://localhost:3000/login?client_id=0oad982lf9nag3MR90h7&scope=openid+profile+offline_access&response_type=id_token+code&relayState=www-perf.connectcdk.com">
        Local - UI Testing
      </a>

      <a href="https://login-dit.connectcdk.com/oidc/authorize?client_id=0oad982lf9nag3MR90h7&scope=openid+profile+offline_access&response_type=id_token+code&relayState=www-qa.connectcdk.com">
        www-qa.connectcdk.com
      </a>
      <a href="https://login-dit.cvrconnect.com/oidc/authorize?client_id=0oae33qbukn1gQKz00h7&scope=openid+profile+offline_access&response_type=id_token+code&relayState=">
        www-qa.cvrconnect.com
      </a>
      <a href="https://login-perf.connectcdk.com/oidc/authorize?client_id=0oad982lf9nag3MR90h7&scope=openid+profile+offline_access&response_type=id_token+code&relayState=www-perf.connectcdk.com">
        www-perf.connectcdk.com
      </a>
      <br />
      <br />

      <a href="http://localhost:8080/authorize?client_id=0oad97k40fSQ3KYFC0h7&scope=openid+profile+offline_access&response_type=id_token+code&redirect_uri=http://localhost:3000">
        Local-ConnectCDK-Perf
      </a>
      <a href="http://localhost:8080/authorize?client_id=0oad982lf9nag3MR90h7&scope=openid+profile+offline_access&response_type=id_token+code&redirect_uri=http://localhost:3000">
        Local-ConnectCDK-DIT
      </a>
      <a href="http://localhost:8080/authorize?client_id=0oae33qbukn1gQKz00h7&scope=openid+profile+offline_access&response_type=id_token+code&redirect_uri=http://localhost:3000">
        Local-CVRConnect
      </a>
      <a href="https://api-dit.connectcdk.com/api/cs-common-login-api/v1/authorize?client_id=0oad982lf9nag3MR90h7&scope=openid+profile+offline_access&response_type=id_token+code&redirect_uri=https://www-dit.connectcdk.com">
        www-qa-new.connectcdk.com
      </a>
      <a href="https://api-dit.connectcdk.com/api/cs-common-login-api/v1/authorize?client_id=0oae33qbukn1gQKz00h7&scope=openid+profile+offline_access&response_type=id_token+code&redirect_uri=www-qa.connectcdk.com">
        www-qa-new.cvrconnect.com
      </a>
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "magenta",
      }}
    >
      <a href="http://localhost:3000/federation?federation=true">
        DMS Federation
      </a>
    </div>
  </div>
);

export default TestContainer;
